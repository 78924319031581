import React from 'react'
import Background1 from '../components/backgrounds/Background1'

const InsurerDashboard = () => {
    return (
        <Background1>

        </Background1>
    )
}

export default InsurerDashboard
